
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-size: 14px;
  line-height: 1.5714;
  font-family: 'Open Sans', sans-serif;
}

:root {
  --noise-image: url('/assets/images/noise.webp');
}

.noise {
  background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"%3E%3Crect x="0" y="0" width="100" height="100" fill="rgba(0, 125, 255, 1)"%3E%3C/rect%3E%3C/svg%3E');
  background-blend-mode: overlay;
}

.btn-noise {
  background-image: var(--noise-image), linear-gradient(to right, rgba(0, 125, 255), rgba(0, 125, 255));
  background-blend-mode: overlay;
}

.noise-text {
  display: inline-block;
  background-image: url('/assets/images/noise.webp');
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 125, 255, 0.75);
}

.noise-text2 {
  background-image: url('/assets/images/noise.webp');
  color: rgba(0, 125, 255, 1);
  background-blend-mode: multiply;
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 125, 255, 0.9);
}

